import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import ButtonLink from "../components/ButtonLink"
import Grid from "../components/grid/Grid"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

// Constains the text and button content for services
const ServiceSection = ({
  className,
  title = "",
  content = null,
  buttonText = "",
  buttonTo = "/",
}) => (
  <section className={`grid gap-y-8 mt-5  ${className} `}>
    <h2>{title}</h2>
    {content}
    <ButtonLink
      arrow
      to={buttonTo}
      className=" justify-self-start"
      text={buttonText}
    />
  </section>
)
// This creates a grid container for each service section
const ServiceGrid = ({ children }) => (
  <Grid className=" gap-y-10 items-start">{children}</Grid>
)

const phsyioContent = (
  <>
    <p>
      We provide manual therapy to help with a wide range of injuries and
      conditions. Our focus is not just to remediate pain but also to provide
      preventative strategies to help optimize your mobility.
    </p>
  </>
)

const rmtContent = (
  <>
    <p>
      Our registered massage therapist (RMT) services provide manual treatment
      that aims to change the amount of tone or tension in different areas of
      the body. We focus on soft tissue, including muscles, fascia (the
      connective tissue around the muscles) and even skin, which can also hold
      tension.
    </p>
  </>
)

const pilatesContent = (
  <>
    <p>
      Our clinical Pilates services are delivered by a fully certified{" "}
      <a
        href="https://www.stottpilates.com/"
        className="inlineLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        STOTT Pilates
      </a>{" "}
      instructor who works with you to align balance and activate core muscles
      and prevent and rehabilitate injuries. We focus on the spine and joints of
      the body to help create free and efficient movement, with less pain and
      tension.
    </p>
  </>
)

const ServiceImage = ({ className, imageData = null }) => {
  return <GatsbyImage image={imageData} imgClassName="rounded-2xl" />
}

const Services = ({ data }) => {
  const physioButtonText = (
    <span>
      <span className="hidden md:inline">Our physiotherapy services</span>{" "}
      <span className="inline md:hidden">Physiotherapy</span>
    </span>
  )
  const rmtButtonText = (
    <span>
      <span className="hidden md:inline">Our registered massage therapy</span>{" "}
      <span className="inline md:hidden">Massage Therapy</span>
    </span>
  )

  const pilatesButtonText = (
    <span>
      <span className="hidden md:inline">Our pilates services</span>{" "}
      <span className="inline md:hidden">Pilates</span>
    </span>
  )
  return (
    <Layout>
      <SEO title="Services" />
      <div className="grid gap-y-10 lg:gap-y-28">
        <Grid>
          <h1 className="col-span-full lg:col-start-2 ">
            We want you to feel your best.
          </h1>
        </Grid>
        {/* PHYSIO SECTION */}
        <ServiceGrid>
          <ServiceSection
            title="Physiotherapy"
            content={phsyioContent}
            buttonText={physioButtonText}
            buttonTo="/services/physiotherapy"
            className="col-span-full lg:col-span-4 lg:col-start-2"
          />
          <div className=" col-span-full col-start-2 md:col-start-4 md:col-end-12 lg:col-start-7 lg:col-end-11 row-start-1">
            <ServiceImage
              imageData={getImage(data.physioImage)}
              alt="Physiotherapy"
            />
          </div>
        </ServiceGrid>

        {/* RMT SECTION */}
        <ServiceGrid>
          <div className="  col-span-full col-end-7 lg:col-start-2 lg:col-span-4">
            <ServiceImage
              imageData={getImage(data.rmtImage)}
              alt="Registered Massage Therapy"
            />
          </div>
          <ServiceSection
            title="Registered Massage Therapy"
            content={rmtContent}
            buttonText={rmtButtonText}
            buttonTo="/services/rmt"
            className=" col-span-full lg:col-start-7 lg:col-end-12"
          />
        </ServiceGrid>

        {/* PILATES SECTION */}
        <ServiceGrid>
          <ServiceSection
            title="Pilates"
            content={pilatesContent}
            buttonText={pilatesButtonText}
            buttonTo="/services/pilates"
            className="col-span-full lg:col-span-5 lg:col-start-2"
          />
          <div className=" col-span-full  lg:col-start-8 lg:col-end-13 row-start-1">
            <ServiceImage
              imageData={getImage(data.pilatesImage)}
              alt="Pilates"
            />
          </div>
        </ServiceGrid>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ServicesQuery {
    physioImage: file(relativePath: { eq: "2022/AD8Q4485.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 75
          width: 650
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    rmtImage: file(relativePath: { eq: "2022/007A5502.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 75
          width: 650
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
    pilatesImage: file(relativePath: { eq: "2022/007A5587.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 75
          width: 650
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`

export default Services
